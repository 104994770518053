import { Alert, AlertIcon, Button, Center, SlideFade } from "@chakra-ui/react"
import { navigate } from "gatsby"
import qs from "query-string"
import React, { useEffect, useState } from "react"
import { BigMessageSpinner } from "../../components/BigMessageSpinner"
import { Layout } from "../../components/layout/Layout"
import { LayoutBody } from "../../components/layout/LayoutBody"
import { BareNav } from "../../components/layout/Nav"
import { getFirebaseApp } from "../../components/auth/FirebaseContext"
import { PageTitle } from "../../components/typing-test/PageTitle"

// Code stolen from:
// https://firebase.google.com/docs/auth/custom-email-handler

/** Forwards the user to the correct page from Firebase-triggered email while keeping the query string */
export default function AccountManagementEmailLanding() {
  // const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState("")

  useEffect(() => {
    const app = getFirebaseApp()

    const { mode, oobCode, continueUrl, lang } = qs.parse(
      window.location.search
    )

    if (typeof oobCode !== "string") {
      throw new Error("Expected `oobCode` to be a string")
    }

    switch (mode) {
      case "resetPassword": {
        // navigate("/account/password-reset" + window.location.search, { replace: true })
        app
          .auth()
          .verifyPasswordResetCode(oobCode)
          .then((email: string) =>
            navigate(
              `/account/password-reset?${qs.stringify({
                email,
                oobCode,
                continueUrl,
              })}`
            )
          )
          .catch((error: Error) => setError(error.message))
        break
      }
      case "verifyEmail": {
        // navigate("/account/email-verified" + window.location.search, { replace: true })
        app
          .auth()
          .applyActionCode(oobCode)
          .then(() =>
            navigate(
              `/account/email-verified?${qs.stringify({
                oobCode,
                continueUrl,
              })}`
            )
          )
          .catch((error: Error) => setError(error.message))
        break
      }
      default:
        throw new Error("Server Error: Invalid and/or missing mode")
    }
  }, [])

  if (error) {
    return (
      <Layout>
        <BareNav />
        <LayoutBody>
          <SlideFade in>
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
            <br />
            <Center>
              <Button as="a" href="/" size="lg">
                Back to Home
              </Button>
            </Center>
          </SlideFade>
        </LayoutBody>
      </Layout>
    )
  }

  // Loading...
  return (
    <Layout>
      <PageTitle>Verifying email link...</PageTitle>
      <BareNav />
      <LayoutBody>
        <BigMessageSpinner message="Verifying email link..." />
      </LayoutBody>
    </Layout>
  )
}
